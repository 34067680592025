import React from 'react'
import { navigate } from 'gatsby'
import * as st from '~/assets/styl/GoBackButton.module.styl'

export default function GoBackButton() {
  return (
    <button className={st.goBack} onClick={() => navigate(-1)}>
      <span className="icon-chevron"></span>
      Voltar
    </button>
  )
}
