import React from 'react'
import { Head } from '~/components'
import Faq from '~/components/Faq'
import Newsletter from '~/components/Newsletter'

const FAQ = () => {
  return (
    <>
      <Head />
      <Faq />
      <Newsletter />
    </>
  )
}

export default FAQ
