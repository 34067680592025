import React, { useState } from 'react'

import cn from 'classnames'
import GoBackButton from './GoBackButton'

import * as st from '~/assets/styl/Faq.module.styl'

import { useApi } from '~/siteApi'
import { graphql, useStaticQuery } from 'gatsby'

export default function Faq() {
  const [activeAccordion, setActiveAccordion] = useState(0)
  const { result: faqs } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allFaqs {
            nodes {
              id
              question
              answer
              types {
                id
                name
                slug
              }
            }
          }
        }
      `).allFaqs.nodes,
    },
    'faqs'
  )

  function handleActiveAccordion(id) {
    if (activeAccordion === id) {
      setActiveAccordion(null)
    } else {
      setActiveAccordion(id)
    }
  }

  return (
    <div className={cn(st.faqContainer, 'page-width')}>
      <GoBackButton />
      <section className={st.faqWrapper}>
        <h1>Dúvidas Frequentes</h1>
        <ul>
          {faqs.map((question, id) => (
            <li
              className={cn({
                [st.active]: activeAccordion === id,
              })}
              key={id}
            >
              <section
                onClick={() => handleActiveAccordion(id)}
                className={cn(st.question, {
                  [st.active]: activeAccordion === id,
                })}
              >
                <span className={st.counter}>{id + 1}</span>
                <h2>
                  {question.question}
                  <span className="icon-chevron"></span>
                </h2>
              </section>
              <div
                className={cn(st.answer, {
                  [st.active]: activeAccordion === id,
                })}
                dangerouslySetInnerHTML={{
                  __html: question.answer.replace(/\n/g, '<br/>'),
                }}
              ></div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}
